import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { Button } from './button'
import StyledRightUpIcon from './right-up-icon'
import { isBrowser } from '../utils/isBrowser'
import { hasWebShare } from '../utils/hasWebShare'

const ShareIconsWrapper = styled(Flex)`
  & * {
    outline: none;
  }
`

const ButtonShape = styled.div`
  padding: 0.5rem 1rem;
  background-color: transparent;
  transition: all 200ms ease-in-out;

  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.colors.text};
    color: #ffffff;
    cursor: pointer;
  }
`

const ShareIcons = (props) => {
  return (
    <ShareIconsWrapper justifyContent="space-between" flexWrap="wrap">
      <FacebookShareButton url={props.url} quote={props.text}>
        <ButtonShape>Facebook</ButtonShape>
      </FacebookShareButton>
      <TwitterShareButton url={props.url} title={props.title}>
        <ButtonShape>Twitter</ButtonShape>
      </TwitterShareButton>
      <TelegramShareButton url={props.url} title={props.title}>
        <ButtonShape>Telegram</ButtonShape>
      </TelegramShareButton>
      <WhatsappShareButton url={props.url} title={props.title}>
        <ButtonShape>WhatsApp</ButtonShape>
      </WhatsappShareButton>
    </ShareIconsWrapper>
  )
}

const share = (props) => {
  navigator
    .share({
      title: props.title,
      text: props.text,
      url: props.url,
    })
    .then(() => console.log('Successful share'))
    .catch((error) => console.log('Error sharing', error))
}

const Share = (props) => {
  if (isBrowser) {
    if (hasWebShare()) {
      return (
        <Button type="button" isBlock onClick={() => share(props)}>
          <FormattedMessage id="product.share" defaultMessage="Share" />
          <StyledRightUpIcon />
        </Button>
      )
    } else {
      return <ShareIcons {...props} />
    }
  }
}

ShareIcons.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
}

ShareIcons.defaultProps = {
  url: '',
}

export default Share

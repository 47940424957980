import getChosenAccessories from './getChosenAccessories'

const calculateTotal = (items, shippingPrice, freeShippingTotal, taxRate) => {
  let subtotal = 0 // Total without shipping or tax
  let shipping = 0
  let tax = 0
  let total = 0
  let isFreeShipping = false

  if (items.length === 0) {
    return false
  }

  items.forEach(item => {
    const { quantity, price } = item
    subtotal += Number(quantity) * Number(price)

    if ('accessories' in item && 'chosenAccessories' in item) {
      if (item.accessories && item.chosenAccessories) {
        const chosenAccessories = getChosenAccessories(item.accessories, item.chosenAccessories)

        chosenAccessories.forEach(a => {
          const { price } = a
          subtotal += Number(quantity) * Number(price)
        })
      }
    }
  })

  if (subtotal >= freeShippingTotal) {
    isFreeShipping = true
    shipping = 0
  } else {
    shipping = shippingPrice
  }

  tax = Number(subtotal * taxRate)

  total = Number(subtotal) + Number(shipping) + Number(tax)

  return {
    subtotal,
    tax,
    shipping,
    total,
    isFreeShipping,
  }
}

export default calculateTotal

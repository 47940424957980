import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import { NoBorderButton } from './button'

const StyledQuantityInput = styled.input`
  text-align: center;
  border: 0;
  background-color: transparent;
  -moz-appearance: textfield;
  min-width: 44px;
  max-width: 64px;
`

const Quantity = props => {
  const { min, max, step, defaultQuantity, intl } = props
  const [quantity, setQuantity] = useState(defaultQuantity || 1)

  useEffect(() => {
    props.onChange(quantity)
  })

  const handleChange = e => {
    const value = e.currentTarget.value

    if (isNaN(value) || value === null || value === '') {
      setQuantity('')
    } else if (value === 0 || value < min) {
      setQuantity(Number(min))
    } else if (value > max) {
      setQuantity(Number(max))
    } else {
      setQuantity(Number(value))
    }
  }

  const handleBlur = e => {
    const value = Number(e.currentTarget.value)

    if (isNaN(value) || value === 0 || value === '') {
      setQuantity(1)
    } else if (value < min) {
      setQuantity(Number(min))
    } else if (value > max) {
      setQuantity(Number(max))
    }
  }

  const handleFocus = e => e.target.select()

  return(
    <>
      <NoBorderButton
        disabled={quantity === min}
        onClick={() => quantity > props.min && setQuantity(Number(quantity) - 1)}
      >
        &minus;
      </NoBorderButton>

      <StyledQuantityInput
        type="number"
        min={min}
        max={max}
        step={step}
        value={quantity}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        inputMode="numeric"
        aria-label={intl.formatMessage({
          id: 'product.quantity',
          defaultMessage: 'Quantity',
        })}
      />

      <NoBorderButton
        disabled={quantity === max}
        onClick={() => quantity < props.max && setQuantity(Number(quantity)+1)}
      >
        &#43;
      </NoBorderButton>
    </>
  )
}

Quantity.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultQuantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
}

Quantity.defaultProps = {
  min: 1,
  max: 10,
  step: 1,
  defaultQuantity: 1,
}

export default injectIntl(Quantity)

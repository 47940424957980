import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

import { Price } from './styles'
import Checkbox from '../checkbox'

const StyledPrice = styled(Price)`
  font-size: 1rem;
  line-height: 1.25;
`

const AccessoriesSelector = ({ accessories, currentLocale, chosenAccessories, onSelect }) => {
  return (
    <Box>
      {accessories.map(item => {
        const isChosen = chosenAccessories.includes(item.id)

        return (
          <Flex
            key={item.id}
            sx={{
              p: 2,
              background: 'white', // isChosen ? 'white' : 'transparent',
              // border: '1px solid',
              // borderColor: 'accessoryBorder',
              boxShadow: isChosen ? '0 4px 14px rgba(0, 0, 0, 0.15)' : null,
              cursor: 'pointer',
              alignItems: 'center',
              transition: 'all 200ms ease-in-out',
              '&:hover,&:focus': {
                boxShadow: !isChosen ? '0 2px 8px rgba(0, 0, 0, 0.1)' : null,
              },
            }}
            onClick={() => onSelect(item.id)}
          >
            <Box sx={{ minWidth: 'auto', mr: 2, flexGrow: 1, lineHeight: 0 }}>
              <Img style={{ width: '5rem', height: '5rem' }} fixed={item.photos[0].file.childImageSharp.fixed} alt={item.title} />
            </Box>

            <Box sx={{ flexShrink: 1 }}>
              <Heading as="h3" variant="accessoryTitle">
                {item.title}
              </Heading>

              <StyledPrice>
                <FormattedNumber
                  style='currency'
                  value={item.price}
                  currency={item.currency}
                  minimumFractionDigits={currentLocale === 'cs' ? 0 : 2}
                  maximumFractionDigits={currentLocale === 'cs' ? 0 : 2}
                />
              </StyledPrice>

              <Text variant="accessoryDescription">
                {item.description}
              </Text>
            </Box>

            <Flex sx={{ minWidth: 'auto', mx: 2, alignItems: 'center' }}>
              <Checkbox checked={isChosen} />
            </Flex>
          </Flex>
        )
      })}
    </Box>
  )
}

AccessoriesSelector.propTypes = {
  accessories: PropTypes.array.isRequired,
  chosenAccessories: PropTypes.array.isRequired,
  currentLocale: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

AccessoriesSelector.defaultProps = {
  currentLocale: 'en',
}

export default AccessoriesSelector

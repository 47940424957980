import React from 'react'
import { PropTypes } from 'prop-types'
import { useField } from 'formik'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'

import ErrorMessage from './error-message'
import Label from './label'
import LabelDescription from './label-description'
import Optional from './optional'

const StyledTextarea = styled.textarea`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid
    ${(props) => (props.hasError
    ? props.theme.colors.error
    : props.theme.colors.inputBorderColor)};
  border-radius: 0;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  resize: vertical;
  overflow: auto;
`

const Textarea = ({ label, labelDescription, optional, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <Flex flexDirection="column" mb={3}>
      <Label htmlFor={props.id || props.name}>
        {label}:
        {optional && <Optional />}
      </Label>
      {labelDescription && (
        <LabelDescription>{labelDescription}</LabelDescription>
      )}
      <StyledTextarea
        className="text-input"
        hasError={hasError}
        id={props.id || props.name}
        {...field}
        {...props}
      />
      {hasError ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </Flex>
  )
}

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  optional: PropTypes.bool,
  name: PropTypes.string,
}

export default Textarea

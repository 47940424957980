import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import media from '../../styles/media'

const dotSize = 8

const buttonStyle = css`
  display: flex;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  outline: none;
  transition: opacity 200ms ease-in-out;

  &[disabled] {
    cursor: default;
    opacity: 0.1;
  }
`

export const StyledButtonBack = styled.button`
  ${buttonStyle}

  ${media.lessThan('mobile')`
    padding-left: 0.25rem;
  `}
`

export const StyledButtonNext = styled.button`
  ${buttonStyle}
  ${media.lessThan('mobile')`
    padding-right: 0.25rem;
  `}
`

export const StyledDotGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  overflow: hidden;
  flex-grow: 0;
`

export const StyledDot = styled.button`
  margin: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: 0;
  line-height: 0;
  width: ${4.5 * dotSize}px;
  height: ${4.5 * dotSize}px;

  span {
    display: block;
    width: ${dotSize}px;
    height: ${dotSize}px;
    border: 1px solid ${props => props.theme.colors.text};
    border-radius: ${props => props.isVideo ? '0' : dotSize}px;
    background-color: ${props => props.current ? props.theme.colors.text : 'transparent'};
    box-shadow: ${props => props.current ? 'inset 0 0 0 1px #fff' : 'inset 0 0 0 2px transparent;'};
    transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  }
`

export const StyledPlay = styled.button`
  margin: 0 0 0 0.5rem;
  padding: 0.5rem;
  appearance: none;
  background-color: ${props => props.current ? props.theme.colors.text : 'transparent'};
  border: 1px solid ${props => props.theme.colors.text};
  border-radius: 2rem;
  color: ${props => props.current ? props.theme.colors.white : props.theme.colors.text};
  cursor: pointer;
  font-size: 0.85rem;
  outline: 0;
  line-height: 0;
`


export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% ;/* Player ratio: 100 / (1280 / 720) */
  background-color: ${props => rgba(props.theme.colors.text, 0.1)};

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

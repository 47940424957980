/* eslint-disable no-case-declarations */
import React from 'react'
import { PropTypes } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { unit, format } from 'mathjs'
import styled from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'

const PropertiesTableWrapper = styled(Box)`
  background-color: #f7f4e4;
  border: 1px solid #eae2b8;
`

const PropertyRow = styled(Flex)`
  &:last-of-type {
    margin-bottom: 0;
  }
`

const renderValue = (property) => {
  if (!property.unit) {
    return <strong>{property.value}</strong>
  } else {
    let inches = false
    let oz = false

    switch (property.unit) {
    case 'cm':
    case 'mm':
      inches = unit(Number(property.value), property.unit).toNumber('inch')
      inches = format(inches, {
        precision: 3,
      })
      break
    case 'g':
      oz = unit(Number(property.value), property.unit).toNumber('oz')
      oz = format(oz, {
        precision: 3,
      })
      break
    }

    return (
      <>
        <strong>{property.value} {property.unit}</strong>
        {inches && <>&nbsp;({inches} in)</>}
        {oz && <>&nbsp;({oz} oz)</>}
      </>
    )
  }
}

const PropertiesTable = (props) => {
  return (
    <PropertiesTableWrapper mb={props.mb || 4} p={3}>
      {props.properties.map(property => (
        <PropertyRow key={property.id} mb={2} flexGrow={1}>
          <Box width="40%">
            <FormattedMessage id={`product.properties.${property.label}`} />
          </Box>
          <Box width="60%">
            {renderValue(property)}
          </Box>
        </PropertyRow>
      ))}
    </PropertiesTableWrapper>
  )
}

PropertiesTable.propTypes = {
  mb: PropTypes.number,
  properties: PropTypes.array,
}

export default injectIntl(PropertiesTable)

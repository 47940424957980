import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex, Heading } from 'rebass/styled-components'

import Arrow from '../arrow'

const NextProduct = ({ nextProduct }) => {
  return (
    <Box py={4}>
      <Heading as='h2' mb={3} fontWeight='normal'>
        Next product
      </Heading>

      <Link to={`/product/${nextProduct.slug}`}>
        <Flex alignItems='center'>
          <Box width={['30%', '33%']} mr={3}>
            <Img
              fluid={nextProduct.featuredImage.childImageSharp.fluid}
              alt={nextProduct.title}
            />
          </Box>

          <Box flexGrow={1}>{nextProduct.title}</Box>

          <Box>
            <Arrow right />
          </Box>
        </Flex>
      </Link>
    </Box>
  )
}

NextProduct.propTypes = {
  nextProduct: PropTypes.shape({
    featuredImage: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
}

export default NextProduct

import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

import ErrorMessage from './error-message'
import Label from './label'
import check from '../images/check.svg'

const checkedStyle = css`
  background-color: ${props => props.theme.colors.primary};
  background-image: url(${check});
  background-repeat: no-repeat;
  background-position: 50% 50%;
`

const StyledCheckbox = styled.input`
  margin-right: 0.5rem;
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border:
    ${({ hasError, theme }) => `1px solid ${hasError ? theme.colors.error : theme.colors.primary}`};
  transition: all 200ms ease-in-out;
  ${props => props.checked ? checkedStyle : null}
`

const CheckboxInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <Flex flexDirection="column" mb={3}>
      <Label style={{ alignItems: 'center' }}>
        <StyledCheckbox
          hasError={hasError}
          id={props.id || props.name}
          {...field}
          {...props}
        />
        {label}
      </Label>
      {hasError ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </Flex>
  )
}

CheckboxInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  optional: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
}

CheckboxInput.defaultProps = {
  optional: false,
  type: 'checkbox',
}

export default CheckboxInput

import { useState, useEffect } from 'react'

import { isBrowser } from '../utils/isBrowser'

export const usePersistedState = (key, defaultValue) => {
  const [state, setState] = useState(
    () => (isBrowser && JSON.parse(localStorage.getItem(key))) || defaultValue,
  )

  useEffect(() => {
    isBrowser && localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}

export const hasWebShare = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if ('share' in navigator && 'canShare' in navigator) { // Chrome has this method to check if he can share
    return navigator.canShare()
  }

  return 'share' in navigator
}

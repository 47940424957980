import React from 'react'
import { PropTypes } from 'prop-types'
import { useField } from 'formik'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'

import ErrorMessage from './error-message'
import Label from './label'
import LabelDescription from './label-description'
import Optional from './optional'
import chevron from '../images/chevron.svg'

const StyledSelect = styled.select`
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url(${chevron});
  background-position: 98% 50%;
  background-repeat: no-repeat;
  background-size: 1rem 0.5rem;
  border: 1px solid
    ${(props) => (props.hasError
    ? props.theme.colors.error
    : props.theme.colors.inputBorderColor)};
  border-radius: 0;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
`

const Select = ({ label, labelDescription, children, optional, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <Flex flexDirection="column" mb={3}>
      <Label htmlFor={props.id || props.name}>
        {label}:
        {optional && <Optional />}
      </Label>
      {labelDescription && (
        <LabelDescription>{labelDescription}</LabelDescription>
      )}
      <StyledSelect
        className="text-input"
        hasError={hasError}
        id={props.id || props.name}
        value={field.value}
        {...field}
        {...props}
      >
        {children}
      </StyledSelect>
      {hasError ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </Flex>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  optional: PropTypes.bool,
  type: PropTypes.string,
}

export default Select

import { apiURL } from '../utils/apiURL'

const url = apiURL()

export const createOrder = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/order-create`, {
      body: JSON.stringify(data),
      method: 'POST',
    })
      .then(response => resolve(response.json()))
      .catch(error => reject(error))
  })
}

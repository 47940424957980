import React from 'react'
import styled, { css } from 'styled-components'

import check from '../images/check.svg'

const checked = css`
  background-color: ${props => props.theme.colors.primary};
  background-image: url(${check});
  background-repeat: none;
  background-position: 50% 50%;
`

const StyledCheckbox = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid ${props => props.theme.colors.primary};
  ${props => props.checked ? checked : null}
  transition: all 200ms ease-in-out;
`

const Checkbox = (props) => {
  return (
    <StyledCheckbox {...props} />
  )
}

export default Checkbox

import styled from 'styled-components'

const PaymentStatus = styled.p`
  color: ${props => {
    switch (props.status) {
    case 'success':
      return props.theme.colors.success
    case 'cancelled':
      return props.theme.colors.warning
    case 'error':
      return props.theme.colors.error
    default:
      return props.theme.colors.text
    }
  }
};
`

export default PaymentStatus

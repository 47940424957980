import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Box, Flex } from 'rebass/styled-components'
import AnimateHeight from 'react-animate-height'
import { scroller } from 'react-scroll'

import Total from './total'
import { BuyButton, UnstyledButton } from './button'
import OrderForm from './order-form'
import PaymentStatus from './payment-status'
import { createOrder } from '../api/order-create'
import { theme } from '../styles/theme'

const Order = ({
  onToggleOrder,
  onCountryChange,
  quantity,
  subtotal,
  total,
  tax,
  taxRate,
  currency,
  productTitle,
  price,
  shipping,
  legibleFreeShipping,
  sku,
  accessories,
  chosenAccessories,
  intl,
}) => {
  // This shows Order form
  const [order, setOrder] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  // Status of order used to show message to customer
  const [orderStatus, setOrderStatus] = useState({
    status: false,
    message: '',
  })

  const onOrderSubmit = values => {
    // Set submitting to true and send it to OrderForm
    setSubmitting(true)

    createOrder({
      quantity,
      subtotal,
      tax,
      total,
      shipping,
      legibleFreeShipping,
      productTitle,
      sku,
      ...values,
    })
      .then(response => {
        setSubmitting(false)

        if (response.status === 'success') {
          setOrder(false)
          setOrderStatus({
            status: 'success',
            message: intl.formatMessage({
              id: 'product.order.success',
              defaultMessage: 'Order successfully placed.',
            }),
          })
        } else {
          scroller.scrollTo('orderScroll', {
            duration: 500,
            offset: -16,
            smooth: 'easeInOutQuint',
          })

          setOrderStatus({
            status: 'error',
            message: response.message || 'Order failed. Please try again.',
          })
        }
      })
      .catch(error => {
        setSubmitting(false)

        setOrderStatus({
          status: 'error',
          message: intl.formatMessage({
            id: 'product.order.failed',
            defaultMessage: error.message || 'Order failed. Please try again.',
          }),
        })
      })
  }

  return (
    <>
      {orderStatus.status && (
        <PaymentStatus status={orderStatus.status}>
          {orderStatus.message}
        </PaymentStatus>
      )}

      <AnimateHeight
        duration={400}
        easing={theme.easing}
        height={order ? 'auto' : 0}
      >
        {quantity > 0 && (
          <Box
            sx={{
              p: 3,
              mb: 3,
              bg: '#f7f4e4',
              border: '1px solid #eae2b8',
            }}
          >
            <Total
              quantity={Number(quantity)}
              subtotal={Number(subtotal)}
              tax={Number(tax)}
              taxRate={Number(taxRate)}
              total={Number(total)}
              currency={currency}
              unitPrice={Number(price)}
              productName={productTitle}
              shipping={shipping}
              accessories={accessories}
              chosenAccessories={chosenAccessories}
            />
          </Box>
        )}

        <Box mb={3}>
          <OrderForm
            onSubmit={values => onOrderSubmit(values)}
            onCountryChange={country => onCountryChange(country)}
            isSubmitting={isSubmitting}
          />
        </Box>

        <Flex justifyContent='center'>
          <UnstyledButton type='button' onClick={() => setOrder(false)}>
            <FormattedMessage
              id='product.order.cancelOrder'
              defaultMessage='Cancel order'
            />
          </UnstyledButton>
        </Flex>
      </AnimateHeight>

      <AnimateHeight
        duration={400}
        easing={theme.easing}
        height={!order ? 'auto' : 0}
        onAnimationEnd={onToggleOrder}
      >
        <BuyButton
          type='button'
          onClick={() => {
            setOrder(true)
            setOrderStatus({
              status: false,
              message: '',
            })
          }}
        >
          <FormattedMessage
            id='product.order.buttonLabel'
            defaultMessage='Order'
          />
        </BuyButton>
      </AnimateHeight>
    </>
  )
}

Order.propTypes = {
  onToggleOrder: PropTypes.func,
  onCountryChange: PropTypes.func,
  quantity: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  taxRate: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  shipping: PropTypes.number.isRequired,
  legibleFreeShipping: PropTypes.bool.isRequired,
  sku: PropTypes.string.isRequired,
  accessories: PropTypes.array,
  chosenAccessories: PropTypes.array,
  intl: PropTypes.object.isRequired,
}

Order.defaultProps = {
  onToggleOrder: () => {},
  onCountryChange: () => {},
  accessories: null,
  chosenAccessories: null,
}

export default injectIntl(Order)

import * as Yup from 'yup'

export const getValidationSchema = (intl) => {
  return Yup.object().shape({
    firstname: Yup.string()
      .min(2, intl.formatMessage({ id: 'order.firstname.min', defaultMessage: 'First name is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.firstname.max', defaultMessage: 'First name is too long.' }))
      .required(intl.formatMessage({ id: 'order.firstname.required', defaultMessage: 'First name is required.' })),
    lastname: Yup.string()
      .min(2, intl.formatMessage({ id: 'order.lastname.min', defaultMessage: 'Last name is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.lastname.max', defaultMessage: 'Last name is too long.' }))
      .required(intl.formatMessage({ id: 'order.lastname.required', defaultMessage: 'Last name is required.' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'order.email.invalid', defaultMessage: 'E-mail is invalid.' }))
      .required(intl.formatMessage({ id: 'order.email.required', defaultMessage: 'E-mail is required.' })),
    message: Yup.string()
      .min(2, intl.formatMessage({ id: 'order.message.min', defaultMessage: 'Message has to be longer than 2 characters.' }))
      .max(2000, intl.formatMessage({ id: 'order.message.max', defaultMessage: 'Message cannot be longer than 2000 characters.' })),
    addressLine1: Yup.string()
      .min(2, intl.formatMessage({ id: 'order.addressLine1.min', defaultMessage: 'Address line 1 is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.addressLine1.max', defaultMessage: 'Address line 1 is too long.' }))
      .required(intl.formatMessage({ id: 'order.addressLine1.required', defaultMessage: 'Address line 1 is required.' })),
    addressLine2: Yup.string()
      .min(1, intl.formatMessage({ id: 'order.addressLine2.min', defaultMessage: 'Address line 2 is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.addressLine2.max', defaultMessage: 'Address line 2 is too long.' })),
    city: Yup.string()
      .min(2, intl.formatMessage({ id: 'order.city.min', defaultMessage: 'City is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.city.max', defaultMessage: 'City is too long.' }))
      .required(intl.formatMessage({ id: 'order.city.required', defaultMessage: 'City is required.' })),
    state: Yup.string()
      .min(1, intl.formatMessage({ id: 'order.state.min', defaultMessage: 'State is too short.' }))
      .max(50, intl.formatMessage({ id: 'order.state.max', defaultMessage: 'State is too long.' })),
    postalCode: Yup.string()
      .min(3, intl.formatMessage({ id: 'order.postalCode.min', defaultMessage: 'Postal code is too short.' }))
      .max(10, intl.formatMessage({ id: 'order.postalCode.max', defaultMessage: 'Postal code is too long.' }))
      .required(intl.formatMessage({ id: 'order.postalCode.required', defaultMessage: 'Postal code is required.' })),
    country: Yup.string()
      .min(1, intl.formatMessage({ id: 'order.country.min', defaultMessage: 'Country is too short.' }))
      .max(5, intl.formatMessage({ id: 'order.country.max', defaultMessage: 'Country is too long.' }))
      .required(intl.formatMessage({ id: 'order.country.required', defaultMessage: 'Country is required.' })),
    acceptTerms: Yup.string()
      .required(intl.formatMessage({ id: 'order.acceptTerms.required', defaultMessage: 'You have to accept terms and conditions.' })),
  })
}

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

const StyledSmall = styled.small`
  /* align-self: center; */
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.15rem 0.25rem;
  background-color: #e8e8e8;
  border-radius: 3px;
`

const Optional = () => {
  return <StyledSmall><FormattedMessage id="forms.optional" defaultMessage="Optional" /></StyledSmall>
}

export default injectIntl(Optional)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { Box, Flex } from 'rebass/styled-components'
import { lighten } from 'polished'

import { getFormatCurrencyOptions } from '../utils/getFormatCurrencyOptions'
import media from '../styles/media'

const Row = styled(Flex)`
  margin: ${props => (props.total ? '0.25rem 0 0' : '0')};
  padding: ${props => (props.total ? '0.5rem 0 0.25rem' : '0.25rem 0')};
  border-top: ${props =>
    props.total
      ? `1px solid ${lighten(0.4, props.theme.colors.text)}`
      : 'none'};
  flex-wrap: wrap;
`

const Label = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1rem;

  ${media.greaterThan('desktop')`
    flex-basis: 65%;
  `}
`

const Quantity = styled(Box)`
  padding-right: 1rem;
  flex-basis: auto;
  flex-grow: 1;
  text-align: right;
  white-space: nowrap;
`

const Price = styled(Box)`
  flex-basis: 20%;
  flex-grow: 1;
  text-align: right;
`

const Total = props => {
  const {
    productName,
    quantity,
    subtotal,
    tax,
    taxRate,
    total,
    unitPrice,
    shipping,
    currency,
    accessories,
    chosenAccessories,
    intl,
  } = props
  const currentLocale = intl.locale
  const formatCurrencyOptions = getFormatCurrencyOptions(
    currentLocale,
    currency,
  )
  const unitPriceText = intl.formatNumber(unitPrice, { ...formatCurrencyOptions })
  const subtotalText = intl.formatNumber(subtotal, { ...formatCurrencyOptions })
  const totalText = intl.formatNumber(total, { ...formatCurrencyOptions })
  const shippingText = intl.formatNumber(shipping, { ...formatCurrencyOptions })
  const taxText = intl.formatNumber(tax, { ...formatCurrencyOptions })

  return (
    <Box>
      <Row>
        <Label>{productName}</Label>
        <Quantity>{quantity} &times;</Quantity>
        <Price>{unitPriceText}</Price>
      </Row>

      {(accessories && chosenAccessories) && accessories.map(a => {
        if (chosenAccessories.includes(a.id)) {
          const accessoryPriceText = intl.formatNumber(a.price, {
            ...formatCurrencyOptions,
          })
          return (
            <Row key={a.id}>
              <Label>
                {a.title}
              </Label>
              <Quantity>&nbsp;</Quantity>
              <Price>{accessoryPriceText}</Price>
            </Row>
          )
        }
      })}

      <Row total>
        <Label>
          {intl.formatMessage({
            id: 'payment.subtotal',
            defaultMessage: 'Subtotal',
          })}
        </Label>
        <Quantity>&nbsp;</Quantity>
        <Price>{subtotalText}</Price>
      </Row>

      {tax !== null && (
        <Row>
          <Label>
            {tax === 0
              ? intl.formatMessage({
                id: 'payment.noTax',
                defaultMessage: 'No tax',
              })
              : intl.formatMessage({
                id: 'payment.taxWithRate',
                defaultMessage: 'Tax ({taxRate} %) VAT',
              }, {
                taxRate: (taxRate * 100),
              })}
          </Label>
          <Quantity>&nbsp;</Quantity>
          <Price>{tax > 0 && taxText}</Price>
        </Row>
      )}

      {shipping !== null && (
        <Row>
          <Label>
            {shipping === 0
              ? intl.formatMessage({
                id: 'payment.freeShipping',
                defaultMessage: 'Free shipping',
              })
              : intl.formatMessage({
                id: 'payment.shipping',
                defaultMessage: 'Shipping',
              })}
          </Label>
          <Quantity>&nbsp;</Quantity>
          <Price>{shipping > 0 && shippingText}</Price>
        </Row>
      )}

      <Row total>
        <Label>
          <strong>
            {intl.formatMessage({
              id: 'payment.total',
              defaultMessage: 'Total',
            })}
          </strong>
        </Label>
        <Quantity>&nbsp;</Quantity>
        <Price>
          <strong>{totalText}</strong>
        </Price>
      </Row>
    </Box>
  )
}

Total.propTypes = {
  quantity: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  shipping: PropTypes.number,
  productName: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  taxRate: PropTypes.number.isRequired,
  unitPrice: PropTypes.number.isRequired,
  accessories: PropTypes.array,
  chosenAccessories: PropTypes.array,
  intl: PropTypes.object,
}

Total.defaultProps = {
  shippingPrice: 0,
  accessories: null,
  chosenAccessories: null,
}

export default injectIntl(Total)

/* eslint-disable max-len */
import fetchJsonp from 'fetch-jsonp'

const url = 'https://extreme-ip-lookup.com/json/'

// Headers could be required external origins to work, but this is solved with JSONP
// const headers = {
//   'Access-Control-Allow-Origin': '*',
//   'Access-Control-Allow-Credentials': 'true',
//   'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//   'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers,Origin,Accept,X-Requested-With,Content-Type,Access-Control-Request-Method,Access-Control-Request-Headers',
// }

export const getGeolocation = () => {
  const geolocation = fetchJsonp(url, {
    method: 'GET',
    // headers,
  })
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
    .catch(error => {
      return error
    })

  return geolocation
}

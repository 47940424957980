import React from 'react'
import styled from 'styled-components'
import arrow from '../images/arrow.svg'

const StyledArrow = styled.img`
  width: 40px;
  height: 24px;
  transform: rotate(${props => props.left ? '180deg' : '0'});
`

const Arrow = (props) => <StyledArrow src={arrow} alt="" {...props} />

export default Arrow
